<!--
 * @Author: 张博洋
 * @Date: 2022-02-21 18:04:45
 * @LastEditTime: 2022-02-21 19:10:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/views/popup.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <van-popup v-model="show"
             round
             position="bottom"
             close-on-popstate
             :overlay="true"
             get-container="body"
             @close="closePopup"
             class="popup">

    <div class="item"
         v-for="(item,index) in list"
         :key="index"
         @click="select(item)">
      {{item}}
    </div>

  </van-popup>
</template>

<script>
export default {
  props: ['list', 'followShow'],
  watch: {
    followShow(val) {
      this.show = val
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
    select(item) {
      this.$emit('closePopup', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  display: flex;
  flex-direction: column;
  padding: 12px 30px;
  max-height: 500px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .item {
    padding: 10px 0;
    font-size: 15px;
    color: #333333;
  }
}
</style>
