<!--
 * @Author: 张博洋
 * @Date: 2022-02-21 15:21:47
 * @LastEditTime: 2022-03-03 18:00:21
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/views/form.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="form-wrap">
    <van-form class="form"
              ref='form'
              @submit="submitAction"
              :readonly="readonly">
      <van-cell-group inset>
        <p>1、您现在病情如何？</p>
        <van-field v-model="form.conditionDesc"
                   rows="2"
                   autosize
                   label="病情描述"
                   name="病情描述"
                   type="textarea"
                   maxlength="50"
                   :rules="[$.rules.required]"
                   placeholder="请输入" />
        <van-field v-model="form.majorSymptoms"
                   rows="2"
                   autosize
                   label="主要临床症状"
                   name="主要临床症状"
                   type="textarea"
                   maxlength="50"
                   :rules="[$.rules.required]"
                   placeholder="请输入" />
        <van-field class="select"
                   v-model="form.clinicalSymptoms"
                   is-link
                   readonly
                   name="临床症状变化"
                   label="临床症状变化"
                   placeholder="请选择"
                   :rules="[$.rules.required]"
                   @click="handleClinicalSymptoms" />

      </van-cell-group>

      <van-cell-group inset>
        <p>2、您现在同时使用哪些药物？</p>
        <van-field v-model="form.drugName"
                   label="药品名称"
                   name="药品名称"
                   maxlength="20"
                   :rules="[$.rules.required]"
                   placeholder="请输入" />
        <div style="display:flex;align-items: flex-end;">
          <van-field v-model="form.dosage"
                     label="每次给药剂量"
                     name="每次给药剂量"
                     maxlength="20"
                     :rules="[$.rules.required]"
                     placeholder="请输入" />
          <van-field class="select ml10"
                     style="height: 44px;"
                     v-model="form.dosageUnit"
                     name="每次给药剂量单位"
                     is-link
                     readonly
                     label=""
                     :rules="[$.rules.required]"
                     placeholder="请选择"
                     @click="handleDosageUnit" />
        </div>

        <van-field class="select"
                   v-model="form.frequency"
                   is-link
                   readonly
                   label="用药频率"
                   name="用药频率"
                   placeholder="请选择"
                   :rules="[$.rules.required]"
                   @click="handleFrequency" />
        <van-field v-model="form.remark"
                   rows="2"
                   autosize
                   label="备注"
                   name="备注"
                   type="textarea"
                   maxlength="50"
                   :rules="[$.rules.required]"
                   placeholder="请输入" />

      </van-cell-group>

      <van-cell-group inset>
        <p>3、您目前使用的药物有没有剂量的变化？</p>

        <van-field class="select"
                   v-model="form.doseChange"
                   is-link
                   readonly
                   label="有无剂量变化"
                   placeholder="请选择"
                   :rules="[$.rules.required]"
                   @click="handleDoseChange" />
        <van-field class="select"
                   v-model="form.therapeuticChange"
                   is-link
                   readonly
                   label="治疗变化"
                   placeholder="请选择"
                   :rules="[$.rules.required]"
                   @click="handleTherapeuticChange" />
        <van-field v-model="form.doseChangeReason"
                   rows="2"
                   autosize
                   label="调整剂量原因"
                   type="textarea"
                   maxlength="20"
                   :rules="[$.rules.required]"
                   placeholder="请输入" />

      </van-cell-group>

      <van-cell-group inset>
        <p>4、用药后有哪里不舒服？什么时候开始的？严重吗？</p>
        <van-field class="select"
                   v-model="form.adverseReaction"
                   is-link
                   readonly
                   label="有无不良事件"
                   placeholder="请选择"
                   :rules="[$.rules.required]"
                   @click="handleAdverseReaction" />

      </van-cell-group>
    </van-form>

    <followPopup :list="list"
                 :followShow="followShow"
                 @closePopup="closePopup"></followPopup>

    <div>
      <van-cell-group inset
                      :style="{marginBottom:($route.name === 'follow.fill'? '120px':'40px')}">
        <van-cell title="表单号"
                  :value="detail.formId" />
        <van-cell title="随访对象"
                  :value="detail.patientName" />
        <van-cell title="随访员"
                  :value="detail.executeName" />
        <van-cell title="执行时间"
                  :value="detail.executeTime ? $.replyTime(detail.executeTime) : ''" />
      </van-cell-group>
    </div>

    <div class="btn-group"
         v-if="$route.name === 'follow.fill'">
      <van-button color="#1AB370"
                  class="btn-order"
                  @click="submitAction"
                  round>提交</van-button>
      <!-- <van-button @click="goBack">测试</van-button> -->
    </div>
  </div>
</template>

<script>
import forms from '@/mixin/Form.js'
import followPopup from './popup.vue'

export default {
  mixins: [forms],
  components: { followPopup },
  props: ['formJson', 'detail'],
  data() {
    return {
      readonly: '',
      form: {
        conditionDesc: '',
        majorSymptoms: '',
        clinicalSymptoms: '',
        drugName: '',
        dosage: '',
        dosageUnit: '',
        frequency: '',
        remark: '',
        doseChange: '',
        therapeuticChange: '',
        doseChangeReason: '',
        adverseReaction: '',
      },
      list: [],
      followShow: false,
      clinicalSymptomsList: ['好转', '恶化', '稳定', '未知'],
      frequencyList: [
        '持续',
        '按需',
        '每日一次(QD)',
        '每日两次(BID)',
        '每日三次(TID)',
        '每日四次',
        '隔日一次',
        '每周一次',
        '每周两次',
        '每周三次',
        '每周四次',
        '每两周',
        '每月一次',
        '每月两次',
        '每月',
        '每两个月',
        '每三个月',
        '每四个月',
        '间歇的',
      ],
      dosageUnitList: ['mg', 'μg', 'g', 'IU', '其他'],
      doseChangeList: ['有', '无'],
      therapeuticChangeList: [
        '停止用药',
        '剂量减少',
        '剂量增加',
        '用药频率调整',
      ],
      adverseReactionList: ['有', '无', '未知'],
    }
  },
  watch: {
    formJson: {
      handler(val) {
        this.form = val
      },
    },
  },
  created() {
    console.log(this.$route.name)
    if (this.$route.name === 'follow.detail') {
      this.readonly = true
    } else {
      this.readonly = false
    }
  },
  mounted() {},
  methods: {
    submitAction() {},
    handleClinicalSymptoms() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.clinicalSymptomsList]
      this.currTag = 'clinicalSymptoms'
    },
    handleFrequency() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.frequencyList]
      this.currTag = 'frequency'
    },
    handleDosageUnit() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.dosageUnitList]
      this.currTag = 'dosageUnit'
    },
    handleDoseChange() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.doseChangeList]
      this.currTag = 'doseChange'
    },
    handleTherapeuticChange() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.therapeuticChangeList]
      this.currTag = 'therapeuticChange'
    },
    handleAdverseReaction() {
      if (this.readonly) return
      this.followShow = true
      this.list = [...this.adverseReactionList]
      this.currTag = 'adverseReaction'
    },
    closePopup(payload) {
      this.followShow = false
      if (!payload) return
      this.form[this.currTag] = payload
      console.log('payload', payload)
    },
    // goBack() {
    //   if (window.navigator.userAgent.indexOf('flutter_app_visit') !== -1) {
    //     window.location.href = 'visitGoBack'
    //   } else {
    //     this.$router.go(-1)
    //   }
    // },
    submitAction() {
      this.appCheckFormAction().then(() => {
        console.log(this.form)
        let params = {
          accountId: this.$route.query.accountId,
          accountType: this.$route.query.accountType,
          formJson: this.form,
          taskId: this.$route.query.taskId,
          visitType: this.$route.query.visitType,
        }
        console.log(params)
        this.$axios({
          type: 'post',
          url: 'visitTask/submitVisitForm',
          data: params,
          elseData: {
            ajaxLoading: true,
            loading: true,
            errorTip: false,
          },
        })
          .then((res) => {
            this.$.toast.success('提交成功')
            if (
              window.navigator.userAgent.indexOf('flutter_app_visit') !== -1
            ) {
              window.location.href = 'visitGoBack'
            } else {
              this.$router.go(-1)
            }
          })
          .catch((err) => {
            console.log(err)
            this.$.toast.error(err)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-wrap {
  display: flex;
  flex-direction: column;
}
/deep/.van-field__control:disabled {
  color: #333 !important;
}
.form {
  .van-cell-group--inset {
    margin: 8px 16px;
  }

  /deep/.van-cell-group {
    padding: 13px 16px 20px 16px;
    margin: 8px;
    p {
      font-size: 16px;
    }
    .van-cell {
      padding: 0;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      .van-field__label:first-of-type {
        margin-top: 0px;
      }
      .van-field__label:not(.van-cell--clickable) {
        margin-top: 8px;
        margin-left: 0;
        font-size: 14px;
        line-height: 3;
        width: 100%;
      }

      .van-field__value {
        border: 1px solid rgba(229, 229, 229, 1);
        border-radius: 4px;
        padding: 9px 12px;
        font-size: 14px;
      }
    }
    .select {
      align-self: flex-end;
      .van-cell__right-icon {
        margin-left: 4px;
        color: #969799;
        position: absolute;
        right: 7px;
        bottom: 10px;
        display: flex;
        align-items: center;
      }
      .van-field__value {
        position: relative;
        padding: 9px 12px;
      }
    }
  }
}
.info {
  .van-cell {
    .van-cell__title {
      font-size: 14px;
      color: #666666;
    }
    .van-cell__value {
      color: #333;
    }
  }
}
.btn-group {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px 25px 30px 25px;
  .van-button {
    width: 100%;
    margin: 0;
  }
}
</style>